define('admin/controllers/setup/credentials', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			finish() {
				if (this.get('pin') == null || typeof this.get('pin') == 'undefined') {
					$.toast({
						text: 'You forgot to enter a pin',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (this.get('pin2') == null || typeof this.get('pin2') == 'undefined') {
					$.toast({
						text: 'You forgot to re-enter your pin',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (this.get('pin') !== this.get('pin2')) {
					$.toast({
						text: 'Your passwords does not match. Try again.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				this.server.put('/api/admins/' + this.get('session.admin._id'), {
					pin: this.get('pin'),
					setup: false
				}).then(res => {
					this.server.delete('/api/adminlogins', {}).then(res2 => {
						this.set('session.authenticated', res2.authenticated);
						this.set('session.admin', res2.admin);
						$.toast({
							text: 'Your office is now ready. Log back in to start your business',
							heading: 'Setup Complete',
							showHideTransition: 'slide',
							position: 'bottom-left',
							bgColor: '#444',
							textColor: 'white'
						});
						this.transitionToRoute('/login');
					}).fail(err => {
						$.toast({
							text: 'Something went wrong. Try again',
							heading: 'CODE 1',
							showHideTransition: 'slide',
							position: 'bottom-left',
							bgColor: '#444',
							textColor: 'white'
						});
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						heading: 'CODE 2',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			previous() {
				this.transitionToRoute('/setup/avator');
			}
		}
	});
});