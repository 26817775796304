define('admin/controllers/gallery/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			del(data) {
				Swal.fire({
					title: `Delete Gallery`,
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.delete('/api/galleries/' + data).then(res => {
							Swal.fire('Deleted!', 'Your product has been deleted.', 'success');

							var myArray = this.get('model.gallery');
							var newArray = myArray.filter(item => item._id != data._id);

							this.get('model.gallery', newArray);
						}).fail(err => {
							$.toast({
								text: 'Something went wrong, Try again.',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			addGallery() {
				let files = Ember.$('#myAwesomeDropzone')[0].dropzone.files;

				this.server.post('/api/galleries', {
					name: this.get('name')
				}).then(async res => {
					if (files.length > 0) {
						let counter = 0;
						let timerInterval;
						Swal.fire({
							title: 'Loading....',
							allowOutsideClick: false,
							showCloseButton: false,
							backdrop: `
					    rgba(0,0,0,0.6)
					    left top
					    no-repeat
					  `,
							didOpen: () => {
								Swal.showLoading();
								for (var i = files.length - 1; i >= 0; i--) {
									var data = new FormData();
									data.append('files', files[i]);
									data.append('_id', res._id);

									$.ajax({
										url: '/api/galleries/upload-flyer',
										type: 'POST',
										data: data,
										cache: false,
										dataType: 'json',
										processData: false, // Don't process the files
										contentType: false // Set content type to false as jQuery will tell the server its a query string request
									}).done(async foo => {
										counter++;
										if (counter == files.length) {
											Swal.close();
											let data = await Ember.$('#myAwesomeDropzone')[0].dropzone.previewsContainer.children;
											data = [];
											$(".dropzone-previews").empty();
											this.transitionToRoute('/gallery/view/' + res._id);
										}
									}).fail(err => {
										Swal.close();
										$.toast({
											text: 'Something went wrong. Try again!',
											// heading: 'Authentication',
											showHideTransition: 'slide',
											position: 'top-right',
											bgColor: '#444',
											textColor: 'white'
										});
									});
								}
							}
						});
						return false;
					}

					$.toast({
						text: 'New Gallery Saved',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					console.log(err);
					$.toast({
						text: 'Something went wrong. Try again!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});