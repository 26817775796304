define('admin/controllers/e-commerce/order', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			refund() {
				let reason = document.getElementById('reason').value; //Choose a Reason

				if (typeof this.get('amount') == 'undefined' || this.get('amount') == null) {
					$.toast({
						text: 'Please enter an amout for this refund',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (reason == 'Choose a Reason') {
					$.toast({
						text: 'Please select the refund reason.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				SlickLoader.enable();
				SlickLoader.setText('Please Wait....');

				this.server.post('/api/refunds', {
					amount: this.get('amount'),
					reason: reason,
					note: this.get('refund_note'),
					charge_id: this.get('model.order.payment_id'),
					order: this.get('model.order._id')
				}).then(res => {
					this.set('amount', null);
					this.set('refund_note', null);
					SlickLoader.disable();
					let refundTotals = res.refunds;
					let mapRefundsAmounts = refundTotals.map(foo => foo.amount);
					let doTheMath = mapRefundsAmounts.reduce((a, b) => a + b, 0);
					res.refundTotal = doTheMath;
					this.set('model.order', res);
					$.toast({
						text: 'Refunds may take up to 5 days to appear on bank statments',
						heading: 'Refund Sent',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					SlickLoader.disable();
					$.toast({
						text: 'Something went wrong. Try again!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},
			shipped() {
				Swal.fire({
					title: `Order has Shipped?`,
					text: 'User will receive a email.',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Shipped!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.put('/api/orders/shipped/' + this.get('model.order._id'), {
							shipped_date: new Date(),
							shipped_by: this.get('session.admin._id'),
							shipped: true,
							status: 'Shipped'
						}).then(res => {

							Swal.fire('Shipped!', 'Goodbye Order!', 'success');

							this.transitionToRoute('/eCommerce/orders');
						}).fail(err => {
							console.log(err);
							$.toast({
								text: 'Something went wrong. Try again!',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			printLable(pdf) {
				const style = '@page { margin-left: 100px !important }';
				printJS({
					printable: '/api/orders/printLable/' + this.get('model.order._id'),
					type: 'pdf',
					showModal: true,
					style: style
				});
			},

			printInvoice() {
				$('#invociePrint').modal('show');
				window.print();
			}
		}
	});
});