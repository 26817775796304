define('admin/controllers/e-commerce/vendors', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		actions: {
			del(v) {
				let vendor_id = v._id;
				Swal.fire({
					title: `Delete ${v.name}?`,
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.delete('/api/vendors/' + v._id).then(res => {
							let newModel = this.get('model.vendors');

							let array = newModel.filter(foo => foo._id !== vendor_id);

							this.set('model.vendors', array);

							Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
						}).fail(err => {
							$.toast({
								text: 'Something went wrong, Try again.',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			addVendor() {
				if (this.get('name') == null || typeof this.get('name') == 'undefined') {
					$.toast({
						text: 'You forgot your company name',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				this.server.post('/api/vendors', {
					name: this.get('name'),
					phone: this.get('phone'),
					email: this.get('email'),
					api: this.get('api')
				}).then(res => {
					this.set('name', null);
					this.set('phone', null);
					this.set('email', null);
					this.set('api', null);
					this.get('model.vendors').pushObject(res);
					$.toast({
						text: 'Vendor was added',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong, Try again.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			keyUp() {
				let status = document.getElementById('status2').value;
				var search = this.get('search') || '';
				var result;

				if (status == 'true') {
					result = true;
				} else {
					result = false;
				}

				this.server.post('/api/vendors/search', {
					search: search,
					status: result
				}).then(res => {
					this.set('model.vendors', res);
				}).fail(err => {
					$.toast({
						text: 'Something went wrong, Try again.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});