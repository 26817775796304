define('admin/routes/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			this.session.checkStatus();
			return Ember.$.getJSON('/api/adminlogins').then(data => {
				this.set('authenticated', data.authenticated);
				this.set('admin', data.admin);
				this.set('lock', data.lock);
				this.set('session.stripe', data.stripe);
				this.set('session.company', data.company);
				console.log(data);
				return {};
			});

			return Ember.RSVP.hash({

				// chat : Ember.$.getJSON('/api/guests').then(res => res),

			});
		},

		afterModel() {
			// this.session.systemCheck();
			// if (this.get('session.lock')) {
			//   return this.transitionTo('/lockscreen');
			// }
		}
	});
});