define('admin/controllers/signup', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			signup() {

				if (typeof this.get('fname') == 'undefined' || this.get('fname') == null) {
					document.getElementById("fname2").style.color = "red";
					$.toast({
						text: 'You forgot your First Name',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});

					return false;
				}
				if (typeof this.get('lname') == 'undefined' || this.get('lname') == null) {
					document.getElementById("lname2").style.color = "red";
					$.toast({
						text: 'You forgot your Last Name',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});

					return false;
				}
				if (typeof this.get('phone') == 'undefined' || this.get('phone') == null) {
					document.getElementById("phone2").style.color = "red";
					$.toast({
						text: 'You forgot your Phone Number',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});

					return false;
				}
				if (typeof this.get('email') == 'undefined' || this.get('email') == null) {
					document.getElementById("email2").style.color = "red";
					$.toast({
						text: 'You forgot your Email',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});

					return false;
				}
				if (typeof this.get('pw') == 'undefined' || this.get('pw') == null) {
					document.getElementById("password2").style.color = "red";
					$.toast({
						text: 'You forgot your Password',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});

					return false;
				}
				if (typeof this.get('tearms') == 'undefined') {
					document.getElementById("checkbox-signin2").style.color = "red";
					$.toast({
						text: 'Please check the Terms and Conditions box to proceed',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});

					return false;
				}

				this.server.post('/api/companies', {
					first_name: this.get('fname'),
					last_name: this.get('lname'),
					phone: this.get('phone'),
					email: this.get('email'),
					password: this.get('password')
				}).then(res => {}).fail(err => {
					$.toast({
						text: 'Please try again',
						heading: 'Authentication Failed',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});