define('admin/initializers/ajax', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;
	function initialize() /* application */{
		$.ajaxSetup({
			contentType: 'application/json',
			dataType: 'json'
		});
	}

	exports.default = {
		name: 'ajax',
		initialize
	};
});