define('admin/components/stripe-elements', ['exports', 'ember-stripe-elements/components/stripe-elements'], function (exports, _stripeElements) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stripeElements.default;
    }
  });
});