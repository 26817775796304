define('admin/routes/e-commerce/orders', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			this.session.systemCheck();
			let company = this.get('company.company._id');
			if (typeof company == 'undefined') company = this.company.getCompany;

			return Ember.RSVP.hash({

				orders: Ember.$.getJSON('/api/orders/byCompany/' + company).then(res => res)

			});
		}
	});
});