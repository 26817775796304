define('admin/services/projects', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		projects: null,
		socket: Boolean

		// init (...args) {
		//     var userID = this.get('sesson.user._id');
		//     this._super(...arguments);
		//     this.set('socket', false);

		//     return Ember.RSVP.hash({
		// 	    projects : Ember.$.getJSON('/api/projects').then(res => {

		// 	    	this.set('projects', res);

		// 	    })
		// 	})
		// },

		// getOne (params) {
		// 	let res = this.get('projects').findBy('_id', params);
		// 	return res;
		// },

		// connection (data) {
		// 	this.set('socket', true);
		// },

		// disconnect (data) {
		// 	this.set('socket', false);
		// },

		// addFile (res) {
		// 	let id = res.project;
		// 	let com = res.file;
		//     let existing = this.get('projects').findBy('_id', id);

		//     console.log(id);
		//     if (existing) {
		//     	existing.files.pushObject(com);
		//     }
		// },

		// comment (data) {
		// 	let pro = data.project;
		// 	let com = data.comment;
		// 	let user = data.user;
		//     let existing = this.get('projects').findBy('_id', pro);

		//     console.log(com);
		//     if (existing) {
		//     	existing.comments.pushObject({
		//     		user 		: user,
		//     		comment 	: com.comment,
		//     		date_added  : com.date_added
		//     	});
		//     	// existing.comments.pushObject(com);
		//     	// this.pushObject(existing.comments, com);
		//     	// this.get('existing.comments').pushObject(com)
		//       // Ember.set(existing.comments).push(com);
		//     }

		// },
	});
});