define('admin/routes/discounts/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			this.session.systemCheck();

			return Ember.RSVP.hash({

				discounts: Ember.$.getJSON('/api/discounts').then(res => res)

			});
		}
	});
});