define('admin/controllers/setup/avator', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			next() {

				this.server.put('/api/admins/' + this.get('session.admin._id'), {
					avator: this.get('session.avator')
				}).then(res => {
					this.transitionToRoute('/setup/credentials');
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			previous() {
				this.transitionToRoute('/setup/personal');
			},

			getAvator() {
				this.session.getAvator();
			},

			saveAvator(a) {
				this.set('session.avator', a);
			}
		}

	});
});