define('admin/components/draggable-dropzone', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var { set } = Ember;

	exports.default = Ember.Component.extend({

		mouseMove() {

			this.set('session.screenTime', 0);
		}

	});
});