define('admin/routes/e-commerce/order', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model(params) {
			//this.session.systemCheck();

			return Ember.RSVP.hash({

				order: Ember.$.getJSON('/api/orders/' + params._id).then(res => {
					let refundTotals = res.refunds;
					let mapRefundsAmounts = refundTotals.map(foo => foo.amount);
					let doTheMath = mapRefundsAmounts.reduce((a, b) => a + b, 0);

					res.refundTotal = doTheMath;

					return res;
				})

			});
		}
	});
});