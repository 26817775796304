define('admin/routes/setup', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		stripe: Ember.inject.service('stripev3'),
		beforeModel() {
			return this.get('stripe').load();
		}
	});
});