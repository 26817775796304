define('admin/routes/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			this.session.systemCheck();
			let company = this.get('company.company._id');
			if (typeof company == 'undefined') company = this.company.getCompany;

			return Ember.RSVP.hash({

				customersCount: Ember.$.getJSON('/api/users/count').then(res => res),

				orders: Ember.$.getJSON('/api/orders/count').then(res => res)

			});
		},
		afterModel() {}
	});
});