define('admin/components/draggable-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var { get } = Ember;

	exports.default = Ember.Component.extend({

		classNames: ['draggableItem'],
		attributeBindings: ['draggable'],
		draggable: 'true',

		dragStart(event) {
			console.log('drag');
			return event.dataTransfer.setData('text/data', get(this, 'content'));
		}

	});
});