define('admin/controllers/setup/company', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			next() {

				let state = document.getElementById('statePicker').value;
				let industry = document.getElementById('industry').value;

				if (this.get('company_name') == null || typeof this.get('company_name') == 'undefined') {
					$.toast({
						text: 'You forgot your company name',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (industry == "" || typeof industry == 'undefined') {
					$.toast({
						text: 'You forgot your companies industry type',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (state == '') {
					$.toast({
						text: 'You forgot your state',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (this.get('zip') == null || typeof this.get('zip') == 'undefined') {
					$.toast({
						text: 'You forgot your zip code',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				this.server.post('/api/companies', {
					name: this.get('company_name'),
					industry: industry,
					phone: this.get('phone'),
					email: this.get('email'),
					address: this.get('address'),
					address2: this.get('address2'),
					city: this.get('city'),
					state: state,
					zip: this.get('zip')
				}).then(res => {
					this.transitionToRoute('/setup/personal');
				}).fail(err => {
					console.log(err);
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			previous() {
				this.transitionToRoute('/setup');
			}
		}
	});
});