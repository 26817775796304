define('admin/routes/e-commerce/products', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			this.session.systemCheck();

			return Ember.RSVP.hash({

				products: Ember.$.getJSON('/api/products').then(res => res),

				vendors: Ember.$.getJSON('/api/vendors').then(res => res),

				categories: Ember.$.getJSON('/api/categories').then(res => res),

				departments: Ember.$.getJSON('/api/departments').then(res => res)

			});
		}
	});
});