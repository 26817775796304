define('admin/controllers/setup/personal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			next() {

				let state = document.getElementById('statePicker').value;
				let dob = document.getElementById('birthdatepicker').value;

				if (this.get('session.admin.last_name') == null || typeof this.get('session.admin.last_name') == 'undefined') {
					$.toast({
						text: 'You forgot your last name',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (this.get('session.admin.address') == null || typeof this.get('session.admin.address') == 'undefined') {
					$.toast({
						text: 'You forgot your address',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (this.get('session.admin.city') == null || typeof this.get('session.admin.city') == 'undefined') {
					$.toast({
						text: 'You forgot your city',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (state == '') {
					$.toast({
						text: 'You forgot your state',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (this.get('session.admin.zip') == null || typeof this.get('session.admin.zip') == 'undefined') {
					$.toast({
						text: 'You forgot your zip code',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				this.server.put('/api/admins/' + this.get('session.admin._id'), {
					middle_name: this.get('session.admin.middle_name'),
					last_name: this.get('session.admin.last_name'),
					address: this.get('session.admin.address'),
					address2: this.get('session.admin.address2'),
					city: this.get('session.admin.city'),
					state: state,
					zip: this.get('session.admin.zip'),
					dob: dob
				}).then(res => {
					this.transitionToRoute('/setup/avator');
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			previous() {
				this.transitionToRoute('/setup/company');
			}
		}
	});
});