define('admin/routes/e-commerce/departments', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			this.session.systemCheck();

			return Ember.RSVP.hash({

				categories: Ember.$.getJSON('/api/categories').then(res => res)

			});
		}
	});
});