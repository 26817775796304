define('admin/components/leaflet-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    test: [37.33548, -121.893028]

  });
});