define('admin/controllers/profile', ['exports'], function (exports) {
      'use strict';

      Object.defineProperty(exports, "__esModule", {
            value: true
      });
      exports.default = Ember.Controller.extend({
            actions: {
                  gAvator() {
                        this.session.getAvator();
                  },

                  saveAvator(a) {
                        this.set('session.admin.avator', a);
                        this.server.put('api/admins/' + this.get('session.admin._id'), {
                              avator: this.get('session.admin.avator')
                        }).then(res => {
                              $.toast({
                                    text: 'Avator Saved',
                                    // heading: '408 Error',
                                    showHideTransition: 'slide',
                                    position: 'top-right',
                                    bgColor: '#444',
                                    textColor: 'white'
                              });
                        }).fail(err => {
                              $.toast({
                                    text: 'Something went wrong. Try again later.',
                                    // heading: '408 Error',
                                    showHideTransition: 'slide',
                                    position: 'top-right',
                                    bgColor: '#444',
                                    textColor: 'white'
                              });
                        });
                  },

                  saveData() {

                        function getDataSet(data) {
                              return this.get('session.admin.' + data);
                        }
                        let getData = getDataSet.bind(this);

                        this.server.put('/api/admins/' + getData('_id'), {
                              first_name: getData('first_name'),
                              last_name: getData('last_name'),
                              phone: getData('phone'),
                              email: getData('email'),
                              address: getData('address'),
                              address2: getData('address2'),
                              city: getData('city'),
                              state: getData('state'),
                              zip: getData('zip'),
                              about_me: getData('about_me')
                        }).then(res => {
                              this.set('session.admin', res);
                              $.toast({
                                    text: 'Information Saved!',
                                    heading: 'Success',
                                    showHideTransition: 'slide',
                                    position: 'top-right',
                                    bgColor: '#444',
                                    textColor: 'white'
                              });
                        }).fail(err => {
                              $.toast({
                                    text: 'Something went rong. Please try again',
                                    heading: '408 Error',
                                    showHideTransition: 'slide',
                                    position: 'top-right',
                                    bgColor: '#444',
                                    textColor: 'white'
                              });
                        });
                  },

                  viewPin() {
                        let pin = document.getElementById("showPin");
                        pin.classList.remove("blurry-text");
                  },
                  resetPassword() {
                        let pw1 = this.get('password1');
                        let pw2 = this.get('password2');

                        if (pw1 == null || pw1 == '') {
                              $.toast('Missing New Password');
                              return false;
                        }
                        if (pw2 == null || pw2 == '') {
                              $.toast('Missing retyped password');
                              return false;
                        }
                        if (pw1 != pw2) {
                              console.log('not a match');
                              $.toast('Passwords does not match. Try again');
                              return false;
                        }

                        this.server.put('/api/admins/resetPassword/' + this.get('session.admin._id'), {
                              password: this.get('password1')
                        }).then(res => {
                              this.set('password1', null);
                              this.set('password2', null);

                              $.toast({
                                    text: 'New Password Saved!',
                                    heading: 'Success',
                                    showHideTransition: 'slide',
                                    position: 'top-right',
                                    bgColor: '#444',
                                    textColor: 'white'
                              });
                        }).fail(err => {
                              $.toast({
                                    text: 'Something went rong. Please try again',
                                    heading: '408 Error',
                                    showHideTransition: 'slide',
                                    position: 'top-right',
                                    bgColor: '#444',
                                    textColor: 'white'
                              });
                        });
                  }
            }
      });
});