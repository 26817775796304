define('admin/initializers/projects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'projects', 'service:projects');
    application.inject('controller', 'projects', 'service:projects');
    application.inject('component', 'projects', 'service:projects');
  }

  exports.default = {
    name: 'projects',
    initialize
  };
});