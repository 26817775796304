define('admin/routes/e-commerce/product', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		// actions : {
		//     willTransition(transition) {
		//     	if (!this.get('leave')) {
		// 	    	let foo = transition.intent.name;
		// 	    	let lowerName = foo.toLowerCase();
		// 	    	let route = lowerName.replace(/\./g,'/');
		// 	    	transition.abort();
		// 	    	Swal.fire({
		// 	    	  title: `Are you sure you want to exit?`,
		// 	    	  text: "You won't be able to revert this!",
		// 	    	  icon: 'warning',
		// 	    	  showCancelButton: true,
		// 	    	  confirmButtonColor: '#3085d6',
		// 	    	  cancelButtonColor: '#d33',
		// 	    	  confirmButtonText: 'Leave'
		// 	    	}).then((result) => {

		// 			  if (result.isConfirmed) {
		// 			  	this.set('leave', true);
		// 			  	return this.transitionTo(foo);				  }
		// 			});
		//     	}

		//     },
		// },

		model(params) {
			this.session.systemCheck();

			return Ember.RSVP.hash({

				product: Ember.$.getJSON('/api/products/' + params.slug).then(res => {
					let defaultProduct = res.defaultProduct;
					if (defaultProduct) {
						this.set('products.mainProduct', res);
						this.set('products.productPhoto', defaultProduct.images[0]);
						this.set('products.defaultProduct', defaultProduct);
					}

					return res;
				}),

				vendors: Ember.$.getJSON('/api/vendors').then(res => res),

				categories: Ember.$.getJSON('/api/categories').then(res => res),

				departments: Ember.$.getJSON('/api/departments').then(res => res),

				stats: Ember.$.getJSON('/api/reports/GetProductStats/' + params.slug).then(res => res)

			});
		}

	} // afterModel () {
	// 	console.log(this.get('variations'))

	// }
	);
});