define('admin/components/qr-scanner', ['exports', 'ember-qr-scanner/components/qr-scanner'], function (exports, _qrScanner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _qrScanner.default;
    }
  });
});