define('admin/routes/e-commerce/vendor', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		actions: {
			willTransition(transition) {
				console.log(transition);
			}
		},

		model(params) {
			this.session.systemCheck();

			let { io, enabled } = this.get('socket');
			console.log(params);
			io.on('edit-' + params._id, data => {

				$.toast({
					text: 'This Vendor was just updated',
					// heading: 'Authentication',
					showHideTransition: 'slide',
					position: 'top-right',
					bgColor: '#444',
					textColor: 'white'
				});
			});

			return Ember.RSVP.hash({

				vendor: Ember.$.getJSON('/api/vendors/' + params._id).then(res => res),

				products: Ember.$.getJSON('/api/vendors/products/' + params._id).then(res => res)

			});
		}
	});
});