define('admin/controllers/setup', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		stripe: Ember.inject.service('stripev3'),

		options: {
			hidePostalCode: false,
			style: {
				base: {
					color: '#000'
				}
			}
		},

		actions: {
			pay(stripeElement) {
				let stripe = this.get('stripe');
				stripe.createToken(stripeElement).then(({ token }) => {
					console.log(token);
				});
			}
		}
	});
});