define('admin/routes/discounts/setup/two', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model(params) {
			this.session.systemCheck();

			return Ember.RSVP.hash({

				discount: Ember.$.getJSON('/api/discounts/' + params._id).then(res => {
					if (res.discount_type == "percent") {
						res.amount_type = "Percent";
						res.symbol = "%";
					} else {
						res.amount_type = "Doller";
						res.symbol = "$";
					}

					return res;
				})

			});
		}
	});
});