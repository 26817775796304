define('admin/controllers/connect/complete', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			logouts() {
				this.server.delete('/api/adminlogins').then(res => {
					this.set('session.authenticated', res.authenticated);
					this.set('session.admin', res.admin);
					this.set('session.lock', res.lock);
					this.transitionToRoute('/logout');
				}).fail(err => {
					console.log(err);
				});
			}
		}
	});
});