define('admin/controllers/e-commerce/products', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			newCheck(foo) {
				if (foo == 'newCategory') {
					Swal.fire({
						title: `Create new Category?`,
						text: "You will be leaving this page",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'New Category'
					}).then(result => {
						if (result.isConfirmed) {
							$('.modal-backdrop.fade.show').removeClass('modal-backdrop fade show');
							return this.transitionToRoute('/eCommerce/categories');
						} else {

							$('#addProduct').modal('show');
							document.getElementById('cat').selectedIndex = 0;
						}
					});
				}

				if (foo == 'newVendor') {
					Swal.fire({
						title: `Create new Vendor?`,
						text: "You will be leaving this page",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'New Vendor'
					}).then(result => {
						if (result.isConfirmed) {
							$('.modal-backdrop.fade.show').removeClass('modal-backdrop fade show');
							return this.transitionToRoute('/eCommerce/vendors');
						} else {

							$('#addProduct').modal('show');
							document.getElementById('vebdor').selectedIndex = 0;
						}
					});
				}
			},

			keyUp() {
				// let status = document.getElementById('status2').value;
				var search = this.get('search') || '';

				this.server.post('/api/products/admin/search', {
					search: search
				}).then(res => {
					this.set('model.products', res);
				}).fail(err => {
					$.toast({
						text: 'Something went wrong, Try again.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			del(p) {
				let product_id = p._id;
				let product_name = p.name;
				Swal.fire({
					title: `Delete ${product_name}?`,
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.delete('/api/products/' + product_id).then(res => {
							Swal.fire('Deleted!', 'Your product has been deleted.', 'success');

							let model = this.get('model.products');

							let sortModel = model.filter(foo => foo._id != product_id);

							this.set('model.products', sortModel);

							// this.transitionToRoute('/eCommerce/products');
						}).fail(err => {
							$.toast({
								text: 'Something went wrong, Try again.',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			addProduct() {
				let cat = document.getElementById('cat').value;
				let vendor = document.getElementById('vendor').value;

				if (this.get('name') == null || typeof this.get('name') == 'undefined') {
					$.toast({
						text: 'You forgot product main name',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (vendor == null || typeof vendor == 'undefined') {
					$.toast({
						text: 'You forgot product vendor',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (cat == "" || typeof cat == 'undefined') {
					$.toast({
						text: 'You forgot product Category',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				let split = cat.split(",");

				this.server.post('/api/producttwos', {
					name: this.get('name'),
					categories: split,
					vendor: vendor
				}).then(res => {
					$.toast({
						text: 'Product Added',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
					this.transitionToRoute('/eCommerce/product/' + res.slug);
				}).fail(err => {
					console.log(err);
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});