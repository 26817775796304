define('admin/controllers/e-commerce/vendor', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		edit: false,

		actions: {
			del() {
				let vendor_id = this.get('model.vendor._id');
				Swal.fire({
					title: `Are you sure you want to delete?`,
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.delete('/api/vendors/' + vendor_id).then(res => {
							Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
							this.transitionToRoute('eCommerce.vendors');
						}).fail(err => {
							$.toast({
								text: 'Something went wrong, Try again.',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			activate() {
				this.server.put('/api/vendors/' + this.get('model.vendor._id'), {
					active: true
				}).then(res => {
					this.set('model.vendor.active', true);
					$.toast({
						text: 'Vendor is now activate!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			deactivate() {
				this.server.put('/api/vendors/' + this.get('model.vendor._id'), {
					active: false
				}).then(res => {
					this.set('model.vendor.active', false);
					$.toast({
						text: 'Vendor is now deactivated',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			save() {
				this.server.put('/api/vendors/' + this.get('model.vendor._id'), {
					name: this.get('model.vendor.name'),
					contact_person: this.get('model.vendor.contact_person'),
					phone: this.get('model.vendor.phone'),
					email: this.get('model.vendor.email'),
					site: this.get('model.vendor.site'),
					api: this.get('model.vendor.api'),
					address: this.get('model.vendor.address'),
					city: this.get('model.vendor.city'),
					state: this.get('model.vendor.state'),
					zip: this.get('model.vendor.zip'),
					country: this.get('model.vendor.country')
				}).then(res => {
					$.toast({
						text: 'Vendor Information Saved!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			edit() {
				let foo = this.get('edit');
				console.log(foo);
				if (foo) {
					this.set('edit', false);
				} else {
					this.set('edit', true);
				}
			}
		}
	});
});