define('admin/controllers/connect/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			start(params, transition) {
				this.server.post('/api/companies/setupConnect').then(res => {
					let url = res.url;
					window.location.replace(url);
				}).fail(err => {
					$.toast({
						text: 'Setup faild. Please logout and re-log into your account.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});