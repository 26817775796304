define('admin/controllers/e-commerce/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		result: '',
		cam: ['foo'],

		actions: {
			getLink() {
				this.server.get('/api/stripes/getLink/' + this.get('session.company.connected_account_id')).then(res => {
					let url = res.url;
					window.location.replace(url);
				}).fail(err => {
					$.toast({
						text: 'Please logout and try again.',
						heading: 'Action Failed',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			onScanSuccess(e) {
				console.log(e);
				this.set('result', e);

				this.set('cam', []);

				setTimeout(() => {
					this.get('cam').pushObject('foo');
				}, 1000);
			}
		}
	});
});