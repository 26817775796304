define('admin/services/products', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),

    productPhoto: null,

    geo: [37.335480, -121.893028],

    systemSearch: [],

    category: {},

    mainProduct: {},

    defaultProduct: {},

    users: [{
      "id": 1,
      "first_name": "Monte",
      "last_name": "Delves",
      "pic": "https://robohash.org/etodionulla.png?size=50x50&set=set1"
    }, {
      "id": 2,
      "first_name": "Burlie",
      "last_name": "Mattheeuw",
      "pic": "https://robohash.org/undeconsecteturea.png?size=50x50&set=set1"
    }, {
      "id": 3,
      "first_name": "Ginni",
      "last_name": "Zaple",
      "pic": "https://robohash.org/magnidoloreprovident.jpg?size=50x50&set=set1"
    }, {
      "id": 4,
      "first_name": "Suzy",
      "last_name": "Hiddy",
      "pic": "https://robohash.org/autquiperspiciatis.png?size=50x50&set=set1"
    }, {
      "id": 5,
      "first_name": "Inger",
      "last_name": "Roycraft",
      "pic": "https://robohash.org/voluptatemautanimi.jpg?size=50x50&set=set1"
    }, {
      "id": 6,
      "first_name": "Modesta",
      "last_name": "Tomasz",
      "pic": "https://robohash.org/quiavoluptatemcupiditate.bmp?size=50x50&set=set1"
    }, {
      "id": 7,
      "first_name": "Silvano",
      "last_name": "Caramuscia",
      "pic": "https://robohash.org/quiaquisquamexercitationem.png?size=50x50&set=set1"
    }, {
      "id": 8,
      "first_name": "Konstanze",
      "last_name": "Nutt",
      "pic": "https://robohash.org/nesciuntvelitiure.png?size=50x50&set=set1"
    }, {
      "id": 9,
      "first_name": "Latrina",
      "last_name": "Whittet",
      "pic": "https://robohash.org/eosenimratione.bmp?size=50x50&set=set1"
    }, {
      "id": 10,
      "first_name": "Jeffie",
      "last_name": "Waterhouse",
      "pic": "https://robohash.org/inventoreadipisciillo.bmp?size=50x50&set=set1"
    }],

    data: {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [{
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
        borderWidth: 1
      }]
    },

    data2: {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [{
        label: '# of Votes',
        data: [5, 3, 3, 5, 8, 15],
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderWidth: 1
      }]
    },

    // init () {
    //   let photoArray = [
    //     '/img/lash/1.jpg',
    //     '/img/lash/2.jpg',
    //     '/img/lash/3.jpg',
    //     '/img/lash/4.jpg'
    //   ];

    //   this.set('photoArray', photoArray);
    //   this.set('productPhoto', photoArray[0]);
    // },

    photos(foo) {
      let firstPhoto = foo[0];
      // console.log(this.get('model.product'))
      if (firstPhoto) {
        this.set('productPhoto', firstPhoto);
        return false;
      } else {
        this.set('productPhoto', '/img/productImage.png');
      }
    },

    setPhoto(data) {
      this.set('productPhoto', data);
    }
  });
});