define('admin/controllers/e-commerce/product-edit', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			delPhoto(foo) {
				Swal.fire({
					title: `Delete?`,
					imageUrl: foo,
					imageHeight: 200,
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.put('/api/products/deletePhoto/' + this.get('model.product.variation._id'), {
							image: foo
						}).then(res => {
							let imgs = this.get('model.product.variation.images');
							let array = imgs.filter(res => res !== foo);
							this.set('model.product.variation.images', array);
							Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
						}).fail(err => {
							console.log(err);
							$.toast({
								text: 'Something went wrong. Try again!',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			del() {
				let product_id = this.get('model.product.variation._id');
				let main = this.get('model.product.main._id');
				Swal.fire({
					title: `Delete Variation`,
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.put('/api/products/variationDelete/' + product_id, {
							product: product_id,
							main: main
						}).then(res => {
							Swal.fire('Deleted!', 'Your product has been deleted.', 'success');
							this.transitionToRoute('/eCommerce/product/' + this.get('model.product.main.slug'));
						}).fail(err => {
							$.toast({
								text: 'Something went wrong, Try again.',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			save() {
				let tags = document.getElementById('tags').value;
				let _id = this.get('model.product.variation._id');
				let files = Ember.$('#myAwesomeDropzone')[0].dropzone.files;

				this.server.put('/api/productvariations/' + this.get('model.product.variation._id'), {
					main: this.get('model.product.main._id'),
					variation: this.get('model.product.variation._id'),
					price: this.get('model.product.variation.price'),
					cost_per_unit: this.get('model.product.variation.cost_per_unit'),
					width: this.get('model.product.variation.width'),
					height: this.get('model.product.variation.height'),
					weight: this.get('model.product.variation.weight'),
					quantity: this.get('model.product.variation.quantity'),
					tags: tags,
					description: this.get('model.product.variation.description')
				}).then(async res => {

					if (files.length > 0) {
						let counter = 0;

						let timerInterval;
						Swal.fire({
							title: 'Loading....',
							allowOutsideClick: false,
							showCloseButton: false,
							backdrop: `
					    rgba(0,0,0,0.6)
					    left top
					    no-repeat
					  `,
							didOpen: () => {
								Swal.showLoading();
								for (var i = files.length - 1; i >= 0; i--) {
									var data = new FormData();
									data.append('files', files[i]);
									data.append('_id', _id);

									$.ajax({
										url: '/api/products/upload-flyer',
										type: 'POST',
										data: data,
										cache: false,
										dataType: 'json',
										processData: false, // Don't process the files
										contentType: false // Set content type to false as jQuery will tell the server its a query string request
									}).done(async foo => {

										this.get('model.product.variation.images').pushObject(foo);
										counter++;

										if (counter == files.length) {
											Swal.close();
											let data = await Ember.$('#myAwesomeDropzone')[0].dropzone.previewsContainer.children;
											data = [];
											$(".dropzone-previews").empty();
										}
									}).fail(err => {
										Swal.close();
										$.toast({
											text: 'Something went wrong. Try again!',
											// heading: 'Authentication',
											showHideTransition: 'slide',
											position: 'top-right',
											bgColor: '#444',
											textColor: 'white'
										});
									});
								}
							}
						});
						return false;
					}

					$.toast({
						text: 'Product information saved',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					console.log(err);
					$.toast({
						text: 'Something went wrong. Try again!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			savePhotos(files) {
				var data = new FormData();
				data.append('files', files);
				data.append('_id', _id);

				$.ajax({
					url: '/api/products/upload-flyer',
					type: 'POST',
					data: data,
					cache: false,
					dataType: 'json',
					processData: false, // Don't process the files
					contentType: false // Set content type to false as jQuery will tell the server its a query string request
				}).then(res => {
					console.log(res);
				}).fail(err => {
					console.log(err);
				});
			},

			photoChange(data) {
				this.products.setPhoto(data);
			}
		}
	});
});