define('admin/controllers/register', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			signup() {
				let terms = document.getElementById('checkbox-signup').checked;

				if (this.get('first_name') == null || typeof this.get('first_name') == 'undefined') {
					$.toast({
						text: 'You forgot your name',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (this.get('phone') == null || typeof this.get('phone') == 'undefined') {
					$.toast({
						text: 'You forgot your phone number',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (this.get('email') == null || typeof this.get('email') == 'undefined') {
					$.toast({
						text: 'You forgot your email',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (this.get('password') == null || typeof this.get('password') == 'undefined') {
					$.toast({
						text: 'You forgot your password',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				if (!terms) {
					$.toast({
						text: 'In order to access our software, you must accept our Terms and Conditions',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});

					return false;
				}

				this.server.post('/api/admins', {
					first_name: this.get('first_name'),
					phone: this.get('phone'),
					email: this.get('email').toLowerCase(),
					password: this.get('password')
				}).then(res => {
					this.transitionToRoute('/login');
					$.toast({
						text: 'Please sign in to begin account setup',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					this.set('password', null);
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});