define('admin/controllers/gallery/view', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			delImage(data) {
				Swal.fire({
					title: `Delete?`,
					imageUrl: data,
					imageHeight: 200,
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.put('/api/galleries/deleteItem/' + this.get('model.gallery._id'), {
							image: data
						}).then(res => {
							let imgs = this.get('model.gallery.items');
							let array = imgs.filter(res => res !== data);
							this.set('model.gallery.items', array);
							Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
						}).fail(err => {
							console.log(err);
							$.toast({
								text: 'Something went wrong. Try again!',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			default(data) {
				this.server.put('/api/galleries/' + this.get('model.gallery._id'), {
					cover: data
				}).then(res => {
					this.set('model.gallery.cover', data);
					$.toast({
						text: 'Cover image saved',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wong. Please try again.',
						heading: 'Error',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			save() {
				let files = Ember.$('#myAwesomeDropzone')[0].dropzone.files;
				this.server.put('/api/galleries/' + this.get('model.gallery._id'), {
					name: this.get('model.gallery.name')
				}).then(async res => {
					if (files.length > 0) {
						let counter = 0;
						let timerInterval;
						Swal.fire({
							title: 'Loading....',
							allowOutsideClick: false,
							showCloseButton: false,
							backdrop: `
					    rgba(0,0,0,0.6)
					    left top
					    no-repeat
					  `,
							didOpen: () => {
								Swal.showLoading();
								for (var i = files.length - 1; i >= 0; i--) {
									var data = new FormData();
									data.append('files', files[i]);
									data.append('_id', this.get('model.gallery._id'));
									data.append('defaultCover', true);

									$.ajax({
										url: '/api/galleries/upload-flyer',
										type: 'POST',
										data: data,
										cache: false,
										dataType: 'json',
										processData: false, // Don't process the files
										contentType: false // Set content type to false as jQuery will tell the server its a query string request
									}).done(async foo => {
										this.get('model.gallery.items').pushObject(foo);
										counter++;
										if (counter == files.length) {
											Swal.close();
											let data = await Ember.$('#myAwesomeDropzone')[0].dropzone.previewsContainer.children;
											data = [];
											$(".dropzone-previews").empty();
										}
									}).fail(err => {
										Swal.close();
										$.toast({
											text: 'Something went wrong. Try again!',
											// heading: 'Authentication',
											showHideTransition: 'slide',
											position: 'top-right',
											bgColor: '#444',
											textColor: 'white'
										});
									});
								}
							}
						});
						return false;
					}

					$.toast({
						text: 'New Gallery Saved',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					console.log(err);
					$.toast({
						text: 'Something went wrong. Try again!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});