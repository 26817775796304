define('admin/helpers/parse-phonenumber', ['exports', 'ember-libphonenumber-js/helpers/parse-phonenumber'], function (exports, _parsePhonenumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _parsePhonenumber.default;
    }
  });
  Object.defineProperty(exports, 'parsePhonenumber', {
    enumerable: true,
    get: function () {
      return _parsePhonenumber.parsePhonenumber;
    }
  });
});