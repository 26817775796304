define('admin/components/my-dropzone', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		value: [],

		// didInsertElement() {
		// 	console.log('inside')
		// },
		// foo () {
		// 	let files = Ember.$('#myAwesomeDropzone')[0].dropzone.files;
		// 	this.set('value', files);
		// 	console.log(files)
		// },

		actions: {
			itemAdded() {
				let files = Ember.$('#myAwesomeDropzone')[0].dropzone.files;
				this.set('value', files);
				console.log(files);
			}
		}
	});
});