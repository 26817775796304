define('admin/routes/setup/avator', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		afterModel() {
			this.session.getAvator();
		}
	});
});