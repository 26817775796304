define('admin/controllers/e-commerce/departments', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {

			openSaveSub(data) {
				document.getElementById('savesub' + data).style.display = 'inline';
			},

			updateSub(data) {
				this.server.put('/api/subcategories/' + data._id, {
					name: data.name
				}).then(res => {
					document.getElementById('savesub' + data._id).style.display = 'none';
					$.toast({
						text: 'Sub-Category was updated',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			subD() {
				let name = this.get('subd');
				if (name == null || typeof name == 'undefined') {
					$.toast('You forgot to add a name');return false;
				}

				this.server.put('/api/categories/addSubCategory/' + this.get('products.category._id'), {
					subName: name
				}).then(res => {
					this.set('products.category', res);
					this.set('subd', null);
					$.toast({
						text: 'Sub-Category was Saved',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			open(data) {
				this.server.get('/api/categories/' + data._id).then(res => this.set('products.category', res)).fail(err => $.toast('something went wrong. try again'));
			},

			inactive(data) {
				this.server.put('/api/categories/state/' + data._id, {
					active: false
				}).then(res => {
					this.set('model.categories', res);
					$.toast({
						text: 'Category was updated',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			active(data) {
				this.server.put('/api/categories/state/' + data._id, {
					active: true
				}).then(res => {
					this.set('model.categories', res);
					$.toast({
						text: 'Category was updated',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			add() {
				let name = this.get('newName');
				if (name == null || typeof name == 'undefined') {
					$.toast({
						text: 'You forgot to name your Category',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'bottom-left',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				this.server.post('/api/categories', {
					name: name
				}).then(res => {
					this.set('newName', null);
					this.get('model.categories').pushObject(res);
					$.toast({
						text: 'Category was added',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			openSave(data) {
				document.getElementById('save' + data).style.display = 'inline';
			},

			update(data) {
				this.server.put('/api/categories/' + data._id, {
					name: data.name
				}).then(res => {
					document.getElementById('save' + data._id).style.display = 'none';
					$.toast({
						text: 'Category was updated',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Try again',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			del(v) {
				let vendor_id = v._id;
				Swal.fire({
					title: `Delete ${v.name}?`,
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.delete('/api/categories/' + v._id).then(res => {
							let newModel = this.get('model.categories');

							let array = newModel.filter(foo => foo._id !== vendor_id);

							this.set('model.categories', array);

							Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
						}).fail(err => {
							$.toast({
								text: 'Something went wrong, Try again.',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			test() {
				Swal.fire({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
					}
				});
			}
		}
	});
});