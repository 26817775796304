define('admin/controllers/permissions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		dragulaconfig: {
			options: {
				copy: false,
				revertOnSpill: false,
				removeOnSpill: false
				// Other options from the dragula source page.
			},
			enabledEvents: ['drag', 'drop']
		},

		actions: {
			onDrop(content, drake) {
				var animalType = content.getAttribute("data-user-id");
				var animalType2 = content.getAttribute("data-user-id");

				animalType2 = 'STEWIE';

				console.log(content);
				console.log(animalType2);
				console.log("drop1");
			}
		}
	});
});