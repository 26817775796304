define('admin/controllers/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		hideMenu: ['login', 'lockscreen', 'forgot', 'logout', 'dialer', 'setup.index', 'signup', 'setup.company', 'setup.personal', 'setup.avator', 'setup.credentials', 'register', 'connect', 'connect.index', 'connect.complete', 'discounts.setup', 'discounts.setup.index', 'discounts.setup.one', 'discounts.setup.two', 'discounts.setup.three'],
		isHideMenuVisible: Ember.computed('currentRouteName', function () {
			return this.get('hideMenu').indexOf(this.get('currentRouteName')) > -1;
		}),

		actions: {
			keyUp() {
				var search = this.get('search') || '';
				var result;

				if (status == 'true') {
					result = true;
				} else {
					result = false;
				}

				this.server.post('/api/systems/search', {
					search: search
				}).then(res => {
					this.set('products.systemSearch', res);
				}).fail(err => {
					$.toast({
						text: 'Something went wrong, Try again.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			openLogin() {
				let address = '/dialer';
				window.open(address, 'Login', 'menubar=no, location=no, toolbar=no, titlebar=no, status=no, scrollbars=no, height=670, width=370,resizable=no');return false;
			},

			lock() {
				this.session.jump();
			},

			logout() {
				this.server.delete('/api/adminlogins', {}).then(res => {
					this.set('session.authenticated', res.authenticated);
					this.set('session.admin', res.admin);
					this.set('session.lock', res.lock);
					this.transitionToRoute('/logout');
				}).fail(err => {
					console.log(err);
				});
			}
		}
	});
});