define('admin/controllers/e-commerce/user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		edit: false,

		actions: {

			printMe() {
				console.log('hello Joe');
			},

			edit() {
				let foo = this.get('edit');
				console.log(foo);
				if (foo) {
					this.set('edit', false);
				} else {
					this.set('edit', true);
				}
			}
		}
	});
});