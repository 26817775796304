define('admin/services/company', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		router: Ember.inject.service(),
		company: null,
		code: null,

		init(...args) {
			// this._super(args);
			this._super(...arguments);

			let address = window.location.hostname;
			this.set('test', window.location.hostname);

			return Ember.RSVP.hash({
				me: Ember.$.getJSON('/api/companies/getByAddress/' + address).then(res => {
					if (res) {
						this.set('company', res);
					} else {
						return false;
					}
				})
			});
		},

		getCompany() {
			let address = window.location.hostname;

			return Ember.$.getJSON('/api/companies/getByAddress/' + address).then(res => {
				return res._id;
			});
		}
	});
});