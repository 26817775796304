define('admin/controllers/login', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			login() {
				this.server.post('/api/adminlogins', {
					email: this.get('email'),
					password: this.get('password')
				}).then(res => {
					this.set('session.authenticated', res.authenticated);
					this.set('session.admin', res.admin);
					this.set('session.admin.password', null);
					this.set('session.stripe', res.stripeData);
					this.set('session.company', res.company);
					this.set('email', null);
					this.set('password', null);
					this.transitionToRoute('index');
				}).fail(err => {
					console.log(err);
					this.set('password', null);
					$.toast({
						text: 'Authentication Failed',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});