define('admin/services/session', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		router: Ember.inject.service(),

		user: {},

		authenticated: false,

		stripeData: null,

		admin: {},

		myRoute: null,

		lock: false,

		server: Ember.inject.service('server'),

		lastPage: null,

		screenTime: 0,

		company: {},

		setup: {

			active: false,

			page1: false,

			page2: false,

			page3: false,

			intro: false
		},

		lockTime: 600,
		warningTime: 570,

		avators: [],

		avator: 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairBigHair&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=Black&eyeType=EyeRoll&eyebrowType=UpDownNatural&mouthType=Smile&skinColor=Light',

		init() {

			console.log(Cookies.get('LiveonbeatAdminLastPage'));

			this.getCompany();

			setInterval(() => {
				let curTime = this.get('screenTime');

				if (window.location.pathname == '/login' || window.location.pathname == '/logout' || window.location.pathname == '/lockscreen' || window.location.pathname == '/setup' || window.location.pathname == '/setup/avator' || window.location.pathname == '/setup/company' || window.location.pathname == '/setup/credentials' || window.location.pathname == '/setup/personal' || window.location.pathname == '/signup' || window.location.pathname == '/register') {
					return false;
				}

				if (curTime == this.get('warningTime')) {
					Swal.close();

					Swal.fire({
						title: 'Inactivity',
						text: "your screen will enter Lockscreen in 30 seconds",
						icon: 'warning',
						button: false,
						showCancelButton: false
					});
				}

				if (curTime == this.get('lockTime')) {

					this.jump();
				}

				if (curTime == this.get('lockTime') + 1) {
					return false;
				}

				this.set('screenTime', curTime + 1);
			}, 1000);
		},

		getCompany() {
			console.log('Hello');
		},

		async getAvator() {
			Array.prototype.sample = function () {
				return this[Math.floor(Math.random() * this.length)];
			};

			this.set('avators', []);

			const avatarOptions = [{
				"key": "accessoriesType",
				"options": ["Blank", "Kurt", "Prescription01", "Prescription02", "Round", "Sunglasses", "Wayfarers"]
			}, {
				"key": "avatarStyle",
				"options": ["Circle"]
			}, {
				"key": "clotheColor",
				"options": ["Black", "Blue01", "Blue02", "Blue03", "Gray01", "Gray02", "Heather", "PastelBlue", "PastelGreen", "PastelOrange", "PastelRed", "PastelYellow", "Pink", "Red", "White"]
			}, {
				"key": "clotheType",
				"options": ["BlazerShirt", "BlazerSweater", "CollarSweater", "GraphicShirt", "Hoodie", "Overall", "ShirtCrewNeck", "ShirtScoopNeck", "ShirtVNeck"]
			}, {
				"key": "eyeType",
				"options": ["Close", "Cry", "Default", "Dizzy", "EyeRoll", "Happy", "Hearts", "Side", "Squint", "Surprised", "Wink", "WinkWacky"]
			}, {
				"key": "eyebrowType",
				"options": ["Angry", "AngryNatural", "Default", "DefaultNatural", "FlatNatural", "RaisedExcited", "RaisedExcitedNatural", "SadConcerned", "SadConcernedNatural", "UnibrowNatural", "UpDown", "UpDownNatural"]
			}, {
				"key": "facialHairColor",
				"options": ["Auburn", "Black", "Blonde", "BlondeGolden", "Brown", "BrownDark", "Platinum", "Red"]
			}, {
				"key": "facialHairType",
				"options": ["Blank", "BeardLight"]
			}, {
				"key": "hairColor",
				"options": ["Auburn", "Black", "Blonde", "BlondeGolden", "Brown", "BrownDark", "PastelPink", "Platinum", "Red", "SilverGray"]
			}, {
				"key": "hatColor",
				"options": ["Black", "Blue01", "Blue02", "Blue03", "Gray01", "Gray02", "Heather", "PastelBlue", "PastelGreen", "PastelOrange", "PastelRed", "PastelYellow", "Pink", "Red", "White"]
			}, {
				"key": "mouthType",
				"options": ["Concerned", "Default", "Disbelief", "Eating", "Grimace", "ScreamOpen", "Serious", "Smile", "Tongue", "Twinkle"]
			}, {
				"key": "skinColor",
				"options": ["Tanned", "Yellow", "Pale", "Light", "Brown", "DarkBrown", "Black"]
			}, {
				"key": "topType",
				"options": ["NoHair", "Eyepatch", "Hat", "Hijab", "Turban", "WinterHat1", "WinterHat2", "WinterHat3", "WinterHat4", "LongHairBigHair", "LongHairBob", "LongHairBun", "LongHairCurly", "LongHairCurvy", "LongHairDreads", "LongHairFrida", "LongHairFro", "LongHairFroBand", "LongHairNotTooLong", "LongHairShavedSides", "LongHairMiaWallace", "LongHairStraight", "LongHairStraight2", "LongHairStraightStrand", "ShortHairDreads01", "ShortHairDreads02", "ShortHairFrizzle", "ShortHairShaggyMullet", "ShortHairShortCurly", "ShortHairShortFlat", "ShortHairShortRound", "ShortHairShortWaved", "ShortHairSides", "ShortHairTheCaesar", "ShortHairTheCaesarSidePart"]
			}];

			function random() {
				return 'https://avataaars.io/?' + avatarOptions.map(opt => opt.options && `${opt.key}=${opt.options.sample()}`).filter(x => x).join('&');
			}

			const times = x => f => {
				if (x > 0) {
					f();
					times(x - 1)(f);
				}
			};

			await times(16)(() => this.get('avators').pushObject(random()));
		},

		isAnthenticated() {
			return this.get(this, 'authenticated');
		},

		lockscreen() {
			this.set('lock', true);
			alert("in");
		},

		unlock() {
			this.admin.set('lockscreen', false);
		},

		checkStatus() {
			return Ember.$.getJSON('/api/adminlogins').then(data => {
				this.set('authenticated', data.authenticated);
				this.set('admin', data.admin);
				this.set('lock', data.lock);
				return {};
			});
		},

		routeTest() {
			this.set('myRoute', window.location.pathname);
		},

		async jump() {
			Swal.close();

			// let page = await window.location.pathname; 
			let page = (await window.location.pathname) + window.location.search;
			Cookies.set('LiveonbeatAdminLastPage', page, { expires: 1 });
			this.set('lastPage', page);

			this.get('server').post('/api/adminlogins/lock').then(res => {

				this.set('session.lock', res.lock);
				this.get('router').transitionTo('lockscreen');
			});

			this.get('router').transitionTo('lockscreen');
		},

		systemCheck() {
			let setup = this.get('admin.setup');
			if (!this.get('authenticated')) {
				return this.get('router').transitionTo('login');
			}
			if (setup) {
				return this.get('router').transitionTo('/setup');
			}
			if (this.get('lock')) {
				return this.get('router').transitionTo('lockscreen');
			}
		}

	});
});