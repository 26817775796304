define('admin/routes/e-commerce/product-edit', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		queryParams: {
			main: {
				refreshModel: true
			},
			defaultProduct: {
				refreshModel: true
			}
		},

		model(params) {
			this.session.systemCheck();

			let main = params.main;
			let defaultProduct = params.defaultProduct || 'NULL';

			return Ember.RSVP.hash({

				product: Ember.$.getJSON(`/api/products/findOneProductVariation/${main}/${defaultProduct}`).then(res => res)

			});
		},

		activate: function () {
			this._super();
			window.scrollTo(0, 0);
		}
	});
});