define('admin/routes/e-commerce/vendors', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			this.session.systemCheck();

			return Ember.RSVP.hash({

				vendors: Ember.$.getJSON('/api/vendors').then(res => res)

			});
		}
	});
});