define('admin/controllers/discounts/setup/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			setup() {
				if (typeof this.get('name') == 'undefined' || this.get('name') == null) {
					$.toast({
						text: 'You forgot to give your discount a name',
						heading: 'Missing Input',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}

				this.server.post('/api/discounts', {
					company: this.get('company.company._id'),
					name: this.get('name')
				}).then(res => {
					this.set('name', null);
					this.transitionToRoute('/discounts/setup/one/' + res._id);
				}).fail(err => {
					$.toast({
						text: 'Please try again',
						heading: 'Discount Failed',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});