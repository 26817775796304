define('admin/helpers/phone-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.phoneNumber = phoneNumber;
  function phoneNumber(params /*, hash*/) {
    var cleaned = ('' + params).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  exports.default = Ember.Helper.helper(phoneNumber);
});