define('admin/controllers/discounts/setup/two', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			next() {
				let amount = this.get('amount');
				let keyword = this.get('keyword') || '';

				if (typeof amount == 'undefined' || amount == null) {
					$.toast({
						text: 'Please do not forget to add your amount',
						heading: 'Missing Input',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				if (this.get('model.discount.discount.keyword_needed')) {
					if (typeof keyword == 'undefined' || keyword == null) {
						$.toast({
							text: 'Please do not forget to add your Keyword',
							heading: 'Missing Input',
							showHideTransition: 'slide',
							position: 'top-right',
							bgColor: '#444',
							textColor: 'white'
						});
						return false;
					}
				}

				this.server.put('/api/discounts/' + this.get('model.discount.discount._id'), {
					amount: amount,
					keyword: keyword
				}).then(res => {
					if (this.get('model.discount.discount.product_discount')) {
						this.transitionToRoute('/discounts/setup/three/' + this.get('model.discount.discount._id'));
					} else {
						Swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Discount Added',
							text: 'Your discount is now active',
							showConfirmButton: false,
							timer: 1500
						});
						this.transitionToRoute('/discounts/view/' + this.get('model.discount.discount._id'));
					}
				}).fail(err => {
					$.toast({
						text: 'Something went wrong. Please try again',
						heading: 'Discount Faild',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});