define('admin/controllers/lockscreen', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			unlock() {
				let lastPage = this.get('session.lastPage') || Cookies.get('LiveonbeatAdminLastPage');

				this.server.post('/api/adminlogins/unlock', {
					pin: this.get('pin')
				}).then(res => {
					console.log(res);
					this.set('pin', null);
					this.set('session.lock', false);
					this.set('products.screenTime', 0);
					if (!lastPage) {
						this.transitionToRoute('index');
					} else {
						this.transitionToRoute(lastPage);
					}
				}).fail(err => {
					this.set('pin', null);
					$.toast({
						text: 'Authentication Failed',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			logout() {
				this.server.delete('/api/adminlogins').then(res => {
					this.set('session.authenticated', res.authenticated);
					this.set('session.admin', res.admin);
					this.transitionToRoute('/logout');
				}).fail(err => {
					console.log(err);
				});
			}
		}
	});
});