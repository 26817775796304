define('admin/controllers/discounts/setup/three', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		selected: [],
		unselected: [],
		selectedAmountEdit: {},

		actions: {
			next() {
				Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: 'Congratulations',
					html: `Your discount <b> ${this.get('model.discount.discount.name')} </b> is ready to go!`,
					showConfirmButton: false,
					timer: 4000
				});
			},

			search() {
				let search = this.get('search');
				if (typeof search == 'unselected' || search == null || search == '' || search == ' ') {
					this.get('search', null);
					return false;
				}
				this.server.get('/api/productvariations/discountProductSearch/' + search).then(res => {
					this.set('unselected', res);
				});
			},

			editItemAmount(data) {
				this.set('selectedAmountEdit', data);
				$('#editItem').modal('show');
			},

			selectWithAmount() {
				let amount = this.get('amount');
				let product = {
					item: this.get('selectedAmountEdit._id'),
					amount: amount
				};
				this.server.put('/api/discounts/addItem/' + this.get('model.discount.discount._id'), {
					item: product
				}).then(res => {
					let data = this.get('selectedAmountEdit');
					this.set('amount', null);
					$('#editItem').modal('hide');
					this.get('model.discount.selected').pushObject(data);
					// this.set('selectedAmountEdit', {});
					$.toast({
						text: `${data.name} was added to discount`,
						heading: 'Discount Updated',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wromg. Please try again.',
						heading: 'Discount Failed',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			select(data) {
				let product = {
					item: data._id,
					amount: this.get('model.discount.discount.amount')
				};
				this.server.put('/api/discounts/addItem/' + this.get('model.discount.discount._id'), {
					item: product
				}).then(res => {
					this.get('model.discount.selected').pushObject(data);
					$.toast({
						text: `${data.name} was added to discount`,
						heading: 'Discount Updated',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					$.toast({
						text: 'Something went wromg. Please try again.',
						heading: 'Discount Failed',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});