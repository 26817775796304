define('admin/components/vector-map', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement() {
			this.initMap();
		},

		initMap() {
			$('#world-map').vectorMap({
				map: 'us_lcc',
				maxLevel: 1,
				backgroundColor: 'transparent',
				markerStyle: {
					initial: {
						fill: '#727cf5',
						stroke: '#ccc'
					}
				},
				markers: this.get('data'),
				regionStyle: {
					initial: {
						fill: '#e3eaef',
						"fill-opacity": 1,
						stroke: 'none',
						"stroke-width": 0,
						"stroke-opacity": 1
					},
					selected: {
						fill: "#2A3F52"
					}

				}

			});
		}
	});
});