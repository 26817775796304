define('admin/controllers/discounts/setup/one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {

			next() {
				let dateSelect = $('#daterangetime').data('daterangepicker');
				console.log(new Date());
				console.log(dateSelect.endDate._d);
				let cart_discount = $('input[name="full-cart"]:checked').val() === 'true';
				let keyword_needed = $('input[name="keyword"]:checked').val() === 'yes';
				let product_discount = $('input[name="full-cart"]:checked').val() === 'false';
				let discount_type = $('input[name="type"]:checked').val();

				if (typeof discount_type == 'undefined') {
					$.toast({
						text: 'Please do not forget to check off everything on this form',
						heading: 'Missing Input',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
					return false;
				}
				this.server.put('/api/discounts/' + this.get('model.discount.discount._id'), {
					cart_discount: cart_discount,
					keyword_needed: keyword_needed,
					product_discount: product_discount,
					discount_type: discount_type,
					start_date: dateSelect.startDate._d || this.get('model.discount.discount.start_date'),
					end_date: this.get('model.discount.discount.end_date') || dateSelect.endDate._d
				}).then(res => {
					this.transitionToRoute('/discounts/setup/two/' + this.get('model.discount.discount._id'));
				}).fail(err => {
					$.toast({
						text: 'Soething went wrong. Please try again.',
						heading: 'Discount Failed',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			}
		}
	});
});