define('admin/router', ['exports', 'admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('lockscreen');
    this.route('login');
    this.route('logout');
    this.route('profile');
    this.route('unique');
    this.route('forgot');
    this.route('dialer');
    this.route('staffing');
    this.route('staff');
    this.route('permissions');
    this.route('setup', function () {
      this.route('personal');
      this.route('avator');
      this.route('credentials');
      this.route('company');
    });
    this.route('signup');
    this.route('register');
    this.route('eCommerce', function () {
      this.route('products');
      this.route('product', { path: 'product/:slug' });
      this.route('categories');
      this.route('departments');
      this.route('discounts');
      this.route('orders');
      this.route('order', { path: 'order/:_id' });
      this.route('vendors');
      this.route('vendor', { path: 'vendor/:_id' });
      this.route('users');
      this.route('user', { path: '/user/:_id' });
      this.route('product_edit');
    });
    this.route('connect', function () {
      this.route('setup');
      this.route('complete');
    });
    this.route('discounts', function () {
      this.route('setup', function () {
        this.route('one', { path: 'one/:_id' });
        this.route('two', { path: 'two/:_id' });
        this.route('three', { path: 'three/:_id' });
        this.route('four', { path: 'four/:_id' });
      });
      this.route('view', { path: 'view/:_id' });
      this.route('edit', { path: 'edit/:_id' });
    });
    this.route('events', function () {
      this.route('list');
      this.route('event');
      this.route('settings');
      this.route('new');
      this.route('edit');
    });
    this.route('gallery', function () {
      this.route('view', { path: 'view/:_id' });
    });
  });

  exports.default = Router;
});