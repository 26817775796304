define('admin/controllers/e-commerce/product', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		edit: false,
		leave: false,
		variations: false,
		variationList: [],
		defaultProduct: {},

		actions: {

			edit_product_page() {
				this.set('products.mainProduct', this.get('model.product'));
				this.transitionToRoute('/eCommerce/product_edit');
			},

			changeProduct(foo) {
				this.set('products.defaultProduct', foo);
				this.set('products.productPhoto', foo.images[0]);
				$("html, body").animate({ scrollTop: "0" });
			},

			active() {

				let activeSwitch = document.getElementById(this.get('products.defaultProduct._id')).checked;
				this.server.put('/api/products/updateQuantity/' + this.get('products.defaultProduct._id'), {
					active: activeSwitch
				}).then(async res => {
					let mainProduct = this.get('products.mainProduct.slug');

					let newModel = await Ember.$.getJSON('/api/products/' + mainProduct).then(foo => foo);

					this.set('model.product', newModel);
					$.toast({
						text: 'Quantity Updated!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					console.log(err);
					$.toast({
						text: 'Something went wrong. Try again!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			delPhoto(foo) {
				Swal.fire({
					title: `Delete?`,
					imageUrl: foo,
					imageHeight: 200,
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.put('/api/products/deletePhoto/' + this.get('model.product.product._id'), {
							image: foo
						}).then(res => {
							let imgs = this.get('model.product.product.images');
							let array = imgs.filter(res => res !== foo);
							this.set('model.product.product.images', array);
							Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
						}).fail(err => {
							console.log(err);
							$.toast({
								text: 'Something went wrong. Try again!',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			del() {
				let product_id = this.get('model.product._id');
				let product_name = this.get('model.product.name');
				Swal.fire({
					title: `Delete ${product_name}?`,
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(result => {
					if (result.isConfirmed) {
						this.server.delete('/api/products/' + product_id).then(res => {
							Swal.fire('Deleted!', 'Your product has been deleted.', 'success');
							this.transitionToRoute('/eCommerce/products');
						}).fail(err => {
							$.toast({
								text: 'Something went wrong, Try again.',
								// heading: 'Authentication',
								showHideTransition: 'slide',
								position: 'top-right',
								bgColor: '#444',
								textColor: 'white'
							});
						});
					}
				});
			},

			updateQuantity() {
				let qty = document.getElementById('productQuantity').value;
				let product = this.get('products.defaultProduct._id');
				this.server.put('/api/products/updateQuantity/' + product, {
					quantity: qty
				}).then(async res => {
					this.set('products.defaultProduct.quantity', qty);
					let mainProduct = this.get('products.mainProduct.slug');

					let newModel = await Ember.$.getJSON('/api/products/' + mainProduct).then(foo => foo);

					this.set('model.product', newModel);

					$.toast({
						text: 'Quantity Updated!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					console.log(err);
					$.toast({
						text: 'Something went wrong. Try again!',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			save() {
				let size = document.getElementById('size').value;
				let tags = document.getElementById('tags').value;

				this.server.put('/api/products/saveVariations/' + this.get('model.product.slug'), {
					upc: this.get('upc'),
					model_number: this.get('model_number'),
					price: this.get('price'),
					cost_per_unit: this.get('cost_per_unit'),
					color: this.get('colorPicker'),
					size: size,
					width: this.get('width'),
					height: this.get('height'),
					weight: this.get('weight'),
					depth: this.get('depth'),
					quantity: this.get('quantity'),
					tags: tags,
					description: this.get('description')
				}).then(async res => {
					this.set('upc', null);
					this.set('model_number', null);
					this.set('price', null);
					this.set('cost_per_unit', null);
					this.set('width', null);
					this.set('height', null);
					this.set('weight', null);
					this.set('depth', null);
					this.set('quantity', null);
					this.set('description', null);

					let defaultProduct = (await res.slug) || "NULL";
					let main = this.get('model.product.slug');
					let newRoute = await `/eCommerce/product_edit?defaultProduct=${defaultProduct}&main=${main}`;
					this.transitionToRoute(newRoute);
					$.toast({
						text: 'Product information saved',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				}).fail(err => {
					console.log(err);
					$.toast({
						text: err.responseJSON || 'Something went wrong, Try again.',
						// heading: 'Authentication',
						showHideTransition: 'slide',
						position: 'top-right',
						bgColor: '#444',
						textColor: 'white'
					});
				});
			},

			savePhotos(files) {
				var data = new FormData();
				data.append('files', files);
				data.append('_id', _id);

				$.ajax({
					url: '/api/products/upload-flyer',
					type: 'POST',
					data: data,
					cache: false,
					dataType: 'json',
					processData: false, // Don't process the files
					contentType: false // Set content type to false as jQuery will tell the server its a query string request
				}).then(res => {
					console.log(res);
				}).fail(err => {
					console.log(err);
				});
			},

			test() {
				let testText = document.getElementById('summernote-editmode').innerHTML;

				console.log(testText);
			},

			photoChange(data) {
				this.products.setPhoto(data);
			},

			edit() {
				let foo = this.get('edit');
				console.log(foo);
				if (foo) {
					this.set('edit', false);
				} else {
					this.set('edit', true);
				}
			}
		}
	});
});