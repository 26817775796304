define('admin/services/socket', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		chatSession: [],
		person: {},
		chatList: [],

		init(...args) {
			this._super(args);
			if (window.hasOwnProperty('WebSocket') && window.hasOwnProperty('io')) {
				this.io = io();
				this.set('enabled', true);
			} else {
				this.set('enabled', false);
			}

			//    return Ember.RSVP.hash({
			//     text : Ember.$.getJSON('/api/guests').then(res => {

			//     	this.set('chatList', res);

			//     })
			// })
		},

		// getChatSession (data) {	
		// 	let person_id = data;

		// 	Ember.$.getJSON('/api/guests/' + person_id)
		// 	.then(res => {
		// 		this.set('person', res);
		// 	})
		// 	.then(() => {
		// 		Ember.$.getJSON('/api/messages/getall/' + person_id)
		// 		.then(res2 => {
		// 			this.set('chatSession', res2);
		// 		})
		// 	});
		// },

		incomingText(data) {
			this.get('chatSession').pushObject(data);
		},

		outgoingText(data) {
			this.get('chatSession').pushObject(data);
		}

	});
});