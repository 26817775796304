define('admin/components/status-view', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement() {
			let getStatus = this.get('status');

			if (getStatus == 'Pending') {
				this.set('setStatus', 'warning');
			}
			if (getStatus == 'Shipped') {
				this.set('setStatus', 'success');
			}
			if (getStatus == 'Canceled') {
				this.set('setStatus', 'danger');
			}
			if (getStatus == 'Draft') {
				this.set('setStatus', 'secondary');
			}
			if (getStatus == 'Ended') {
				this.set('setStatus', 'primary');
			}
			if (getStatus == 'Started') {
				this.set('setStatus', 'success');
			}
			if (getStatus == 'Awaiting Start') {
				this.set('setStatus', 'info');
			}

			if (getStatus == true) {
				this.set('setStatus', 'success');
				this.set('status', 'Active');
			}
			if (getStatus == false) {
				this.set('setStatus', 'danger');
				this.set('status', 'Inactive');
			}
		}
	});
});