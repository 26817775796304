define('admin/routes/connect/complete', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		// afterModel () {
		// 	$('#congrats').modal('show');
		// }
	});
});