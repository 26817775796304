define('admin/routes/e-commerce/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			return Ember.RSVP.hash({

				stats: Ember.$.getJSON('/api/reports').then(res => res)

			});
		}
	});
});