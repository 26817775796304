define('admin/controllers/dialer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			key(data) {

				if (this.get('number')) {
					let num = this.get('number');
					let newNum = num + data;

					// let foo = new AsYouType('US').input(newNum);
					let foo = new libphonenumber.AsYouType('US').input(newNum);

					this.set('number', foo);
				} else {
					this.set('number', data);
				}
			},

			clear() {
				this.set('number', null);
			}
		}
	});
});