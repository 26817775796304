define('admin/routes/gallery/view', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model(params) {
			this.session.systemCheck();

			return Ember.RSVP.hash({

				gallery: Ember.$.getJSON(`/api/galleries/${params._id}`).then(res => res)

			});
		},
		activate: function () {
			this._super();
			window.scrollTo(0, 0);
		}
	});
});